import classNames from 'classnames'
import React, { ReactElement } from 'react'

export function IconButton({
  onClick,
  children,
  size,
  className,
  disabled = false,
  ariaLabel = '',
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: ReactElement
  size: 'xs' | 's' | 'm' | 'l'
  className?: string
  disabled?: boolean
  ariaLabel?: string
}) {
  const sizeClassMapper = {
    xs: 'w-6 h-6 m-3',
    s: 'w-7 h-7 m-4',
    m: 'w-8 h-8 m-5',
    l: 'w-8 h-8 m-6',
  }

  return (
    <button
      aria-label={ariaLabel}
      onClick={onClick}
      className={classNames(
        'rounded-full shadow-icon-button border-2 border-transparent bg-core-white',
        'transition-all hover:bg-core-purple-10',
        disabled
          ? 'opacity-20 cursor-not-allowed'
          : 'active:border-core-purple-90',
        className
      )}
    >
      {React.cloneElement(children, {
        className: `${sizeClassMapper[size]} ${children.props.className}`,
      })}
    </button>
  )
}
